import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StaticImage } from 'gatsby-plugin-image';
import PageTemplate from '../components/page-template';

const About = () => {
  return (
    <PageTemplate className="d-grid gap-6 mb-5">
      <Row className="hero">
        <Col className="hero-image-container" sm={6} xs={12}>
          <StaticImage
            alt="Polina Fiksson"
            src="../images/polina2.png"
          />
        </Col>
        <Col className="hero-container" sm={6} xs={12}>
          <div>
            <h1 className="large-text">My Story & Approach</h1>
            <p className="about-caption">
              Hi! I’m Polina, a Certified Mind Body Nutrition coach and a Mindful Eating Practitioner. I’m passionate about helping women to ditch dieting, restore trust with their body, have a stress-free relationship with food and feel comfortable in their own skin.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="about-container-row">
        <Col>
          <p>
            One of the biggest reasons I wanted to become a nutrition and body image coach was because I was struggling with food and insecurities about my body for years.  I tried all popular diets, religiously counted calories and macros, completely removed sugar and other food groups from my diet and often used food to deal with difficult emotions. I hated and felt ashamed of my body. After nutrition school, I became totally obsessed with eating “perfectly” –  to the point where I was verging on an eating disorder…My relationship with food was stressful and incredibly exhausting. As a result, both my mental and physical health started to deteriorate and I decided I just couldn’t do it anymore. I was finally ready to try something new.
          </p>
          <p>
            Thankfully, I discovered Intuitive and Mindful Eating, became professionally certified in Dynamic Eating Psychology and Mindful Eating and changed my approach to healthy eating and wellbeing.
          </p>
          <p>
            Slowly, I started healing my relationship with food and my body. Fast forward to today and I’m a completely different person:
            <ul>
              <li>I have a balanced and flexible approach to nutrition</li>
              <li>I’m free from guilt, shame and anxiety</li>
              <li>I learned to listen to my body to understand how to nourish it without unnecessary food restrictions</li>
              <li>I’m no longer preoccupied with food and shrinking/ changing my body</li>
              <li>I have learned to realize that my body size or shape do not define my worth and I respect my body with self-love</li>
              <li>I feel healthier and  stronger than I ever had before</li>
            </ul>
          </p>
          <p>
            I would have loved having someone to guide me back then, to help me navigate the confusing and overwhelming world of eating, so I’m here now to help guide you. Through my education and personal experience, I am now able to help clients learn how to truly listen to their body  and feel informed about how to eat to be healthy, without diets or rigid rules, develop nourishing and sustainable habits, and feel comfortable in their own skin.
          </p>
          <p>
            My work combines strategies from Eating Psychology, Mindful Eating practices and Nutrition Science, and focusing on what’s right for your unique body. As a non diet nutrition and body image coach, I take a weight neutral approach.
          </p>
          <p>
            I can’t wait to work together and support you on your journey to healthier, happier you!
          </p>
        </Col>
      </Row>
      <Row className="credentials-container-row">
        <div className="large-text">
          <h2>My Credentials</h2>
        </div>
        <Col className="credentials-container-col" sm={8} xs={12}>
          <ul>
            <li>
              <h5><i class="bi bi-check2"></i> Mindful Eating Training Institute</h5>
              <p className="font-italic"> Certified Mindfulness and Mindful Eating Practitioner</p>
            </li>
            <li>
              <h5><i class="bi bi-check2"></i> Institute for the Psychology of Eating</h5>
              <p className="font-italic">Certified Mind Body Eating Coach</p>
            </li>
            <li>
              <h5><i class="bi bi-check2"></i> Body Image Coaching Mentorship with Summer Innanen</h5>
              <p className="font-italic"> Continuing Body Image Education</p>
            </li>
            <li>
              <h5><i class="bi bi-check2"></i> Body Image Training by Marci Evans + Fiona Sutherland </h5>
              <p className="font-italic">Continuing Body Image Education</p>
            </li>
            <li>
              <h5><i class="bi bi-check2"></i> The Institute of Transformational Nutrition </h5>
              <p className="font-italic">Certified Transformational Nutrition Coach</p>
            </li>
          </ul>
        </Col>
        <Col className="certificates-container-img" sm={4} xs={12}>
          <StaticImage
            alt="Certificates"
            src="../images/certificates.png"
          />
        </Col>
      </Row>
    </PageTemplate>
  )
};

export default About;
